import { useEffect } from "react";
import useSound from "use-sound";
import { useAppContext } from "@/contexts/AppProvider";
import Answer from "./Answer";

const Audio = () => {
  const { data } = useAppContext();
  const { active } = data;

  const [play] = useSound(`/assets/audio/ringtone.mp3`, {
    interrupt: true,
  });

  useEffect(() => {
    if (active) {
      play();
    }
  }, [active]); // eslint-disable-line react-hooks/exhaustive-deps

  return data?.answer && <Answer />;
};

export default Audio;
