import React, { createContext, useReducer, useContext } from "react";
import { reducer, initialData } from "./AppReducer";

export const Context = createContext();

function AppProvider(props) {
  const [data, dispatch] = useReducer(reducer, initialData);

  const value = { data, dispatch };

  return <Context.Provider value={value} {...props} />;
}

function useAppContext() {
  return useContext(Context);
}

export { AppProvider, useAppContext };
