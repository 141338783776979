import { useEffect } from "react";
import { useAppContext } from "@/contexts/AppProvider";
import SpeechRecognition from "react-speech-recognition";
import { SET_APP } from "@/actions/app";

const language = "ru-RU";

const Answer = () => {
  const { data, dispatch } = useAppContext();

  const onEnd = () => {
    SpeechRecognition.startListening({ continuous: true, language });
    dispatch(SET_APP(["loading"], false));
  };

  useEffect(() => {
    const synth = window.speechSynthesis;
    const utterance = new SpeechSynthesisUtterance(data?.answer);
    utterance.lang = language;

    synth.speak(utterance);

    utterance.addEventListener("end", onEnd);
    return () => {
      utterance.removeEventListener("end", onEnd);
      synth.cancel();
    };
  }, [data?.answer]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};

export default Answer;
