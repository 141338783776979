export const orbFrom = {
  opacity: 1,
  scaleX: 1,
  scaleY: 1,
  translateX: 0,
  translateY: 0,
};

export const orbTo = {
  opacity: 0,
  scaleX: 0.1,
  scaleY: 0.1,
};

export const videoFrom = {
  opacity: 0,
  translateX: -150,
  scaleX: 0.1,
  scaleY: 0.1,
};

export const videoTo = {
  opacity: 1,
  scaleX: 1,
  scaleY: 1,
};
