import { fromJS } from "immutable";
import _ from "lodash";

export const initialData = {
  transcript: "",
  active: false,
  loading: false,
  answer: "",
};

export const reducer = (state = initialData, action) => {
  var newState, $var;
  switch (action.type) {
    case "SET_APP":
      newState = fromJS(state).setIn(action.path, action.value);
      return newState.toJS();
    case "SET_APPS":
      newState = fromJS(state);
      if (action.arr && action.arr.length !== 0) {
        action.arr.forEach((values) => {
          newState = newState.setIn(values.path, values.value);
        });
      }
      return newState.toJS();
    case "SET_APP_BY_PARAM":
      newState = fromJS(state);
      if (action.param.length !== 2) return newState.toJS();
      const [name, value] = action.param;
      $var = newState.getIn(action.path).toJS();
      $var = _.findIndex($var, { [name]: value });
      if ($var === -1) return newState.toJS();
      Object.keys(action.value).forEach((key) => {
        newState = newState.setIn(
          [...action.path, $var, key],
          action.value[key]
        );
      });
      return newState.toJS();
    case "PUSH_APP":
      $var = fromJS(state).getIn(action.path);
      if (!$var) {
        $var = fromJS(state).setIn(action.path, []);
        state = $var.toJS();
      }
      newState = fromJS(state).updateIn(action.path, (data) =>
        data.push(action.value)
      );
      return newState.toJS();
    case "UNSHIFT_APP":
      $var = fromJS(state).getIn(action.path);
      if (!$var) {
        $var = fromJS(state).setIn(action.path, []);
        state = $var.toJS();
      }
      newState = fromJS(state).updateIn(action.path, (data) =>
        data.unshift(action.value)
      );
      return newState.toJS();
    case "REMOVE_APP_BY_PARAM":
      newState = fromJS(state);
      $var = newState
        .getIn(action.path)
        .filter((o) => o.get(action.key) !== action.value);
      return newState.setIn(action.path, $var).toJS();
    case "REMOVE_APP_BY_VALUE":
      newState = fromJS(state);
      $var = newState.getIn(action.path).filter((o) => o !== action.value);
      return newState.setIn(action.path, $var).toJS();
    case "INIT":
      return { ...initialData };
    default:
      return state;
  }
};
