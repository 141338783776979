import { Tooltip } from "react-tooltip";
import "./index.less";
import { useAppContext } from "@/contexts/AppProvider";
import { Typewriter } from "react-simple-typewriter";

const Text = () => {
  const { data } = useAppContext();

  const getContent = () => {
    return <Typewriter words={[data?.answer]} loop={1} typeSpeed={25} />;
  };

  return (
    <>
      <Tooltip
        anchorSelect=".answer"
        content={getContent()}
        isOpen={!!data?.answer}
      />
      <div className="answer"></div>
    </>
  );
};

export default Text;
