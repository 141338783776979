import { useEffect } from "react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { useAppContext } from "@/contexts/AppProvider";
import axios from "@/actions/axios";
import { useRef } from "react";
import stringSimilarity from "string-similarity-js";
import { SET_APP, SET_APPS } from "@/actions/app";

const WAITING_TIME = 10000;
const LISTENING_TIME = 2000;

const HELLO = "привет";
const NAME = "берта";

const compare = (word1, word2) => {
  return (
    (word1 &&
      word2 &&
      word1.toLocaleLowerCase() === HELLO &&
      stringSimilarity(NAME, word2.toLocaleLowerCase()) > 0.5) ||
    (word2.toLocaleLowerCase() === HELLO &&
      stringSimilarity(NAME, word1.toLocaleLowerCase()) > 0.5)
  );
};

const Recognition = () => {
  const { data, dispatch } = useAppContext();
  const { active } = data;

  const thread_id = data?.thread_id;
  const assistant_id = data?.assistant_id;

  const listeningTimer = useRef();
  const waitingTimer = useRef();

  const commands = [
    {
      command: "Привет берта",
      callback: (command, spokenPhrase, similarityRatio) => {
        console.log(command, spokenPhrase, similarityRatio);
        const [word1, word2] = spokenPhrase.split(" ");
        if (compare(word1, word2)) {
          dispatch(SET_APP(["active"], true));
        }
        resetTranscript();
      },
      isFuzzyMatch: true,
      fuzzyMatchingThreshold: 0.6,
    },
  ];

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition({ commands });

  console.log(listening);

  useEffect(() => {
    SpeechRecognition.startListening({ continuous: true, language: "ru-RU" });
    return () => {
      SpeechRecognition.stopListening();
    };
  }, []);

  useEffect(() => {
    if (active) {
      dispatch(SET_APP(["transcript"], transcript));
    } else {
      dispatch(SET_APP(["answer"], ""));
    }
  }, [active, transcript]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (active && listening) {
      clearTimeout(listeningTimer.current);
      clearTimeout(waitingTimer.current);

      if (transcript) {
        listeningTimer.current = setTimeout(async () => {
          dispatch(
            SET_APPS([
              { path: ["loading"], value: true },
              { path: ["answer"], value: "" },
            ])
          );

          let url = "/messages";
          if (thread_id) url += `/${thread_id}`;
          SpeechRecognition.abortListening();
          const { data } = await axios.post(url, {
            device_id: "1",
            text: transcript,
            assistant_id,
          });
          dispatch(SET_APP(["answer"], data?.answer));
          dispatch(SET_APP(["thread_id"], data?.thread_id));
          dispatch(SET_APP(["assistant_id"], data?.assistant_id));
          resetTranscript();
        }, LISTENING_TIME);
      } else if (!active) {
        dispatch(SET_APP(["answer"], ""));
        dispatch(SET_APP(["thread_id"], null));
        dispatch(SET_APP(["assistant_id"], null));
      } else {
        waitingTimer.current = setTimeout(() => {
          console.log("waiting timeout");
          if (!transcript) {
            dispatch(SET_APP(["active"], false));
            resetTranscript();
          }
        }, WAITING_TIME);
      }

      return () => {
        clearTimeout(listeningTimer.current);
      };
    }
  }, [active, listening, transcript]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }

  return null;
};

export default Recognition;
