import { useEffect, useRef } from "react";
import { useAppContext } from "@/contexts/AppProvider";
import { Controller, animated } from "@react-spring/web";
import { orbFrom, orbTo, videoFrom, videoTo } from "./data";
import Lottie from "lottie-react";
import animation from "./animation.json";
import "./index.less";

const Indicator = () => {
  const { data } = useAppContext();
  const { active, loading } = data;

  const ref = useRef();
  const lottieRef = useRef();

  const video = useRef(new Controller(videoFrom));
  const orb = useRef(new Controller(orbFrom));

  useEffect(() => {
    if (loading) {
      video.current.start(videoTo);
      orb.current.start(orbTo);
    } else {
      video.current.start(videoFrom);
      orb.current.start(orbFrom);
    }
  }, [loading]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (lottieRef.current) {
      if (active) {
        lottieRef.current.goToAndPlay(1);
      } else {
        lottieRef.current.goToAndStop(1);
      }
    }
  }, [active]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className="indicator">
        <animated.div className="orb" style={orb.current.springs}>
          <Lottie lottieRef={lottieRef} animationData={animation} loop={true} />
        </animated.div>
        <animated.div
          className="video__container"
          style={video.current.springs}
        >
          <video ref={ref} className="video" loop muted autoPlay>
            <source src="/assets/video.mp4" type="video/mp4" />
          </video>
        </animated.div>
      </div>
    </>
  );
};

export default Indicator;
