import { Indicator, Recognition, Audio, Text } from "./components";
import { createContext } from "react";
import { AppProvider } from "./contexts/AppProvider";
import "./App.less";

export const Context = createContext();

function App() {
  return (
    <AppProvider>
      <img className="logo" src={"/assets/logo.png"} alt="logo" />
      <Text />
      <Audio />
      <Recognition />
      <Indicator />
    </AppProvider>
  );
}

export default App;
